body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* This will center the chat box vertically */
}

.chat-box {
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.user-message, .assistant-message {
  font-size: 16pt;
  line-height: 1.5;
  margin-bottom: 2em; /* This will create double spacing between messages */
}

.user-message {
  text-align: right;
  align-self: flex-end;
}

.assistant-message {
  text-align: left;
  align-self: flex-start;
}

.input-container {
  display: flex;
  width: 100%;
}

.text-input {
  width: calc(100% - 60px); /* Adjust this value based on the width of your submit button */
  font-size: 14pt;
  line-height: 1.15;
  background-color: rgba(255, 255, 255, 0.2);
  color: inherit; /* This will make the text color the same as the messages */
  text-align: left;
  border: none;
  outline: none;
  padding: 1em;
}

.text-input:focus {
  outline: none;
}

.submit-button {
  width: 60px; /* Adjust this value based on your design */
}

/* This will make the cursor white and blinking */
.text-input::placeholder {
  color: white;
}