@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');

.App {
  background-color: #f8f9fa;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #282c34;
}

.App-header {
  text-align: center;
  padding: 20px;
}

/* CSS */

body {
  background: linear-gradient(to right, darkblue, rgb(0, 0, 75));
  color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  font-family: sans-serif;
}

.container {
  text-align: center;
}

h1 {
  color: #dddddd58;
  font-size: 1.5em;
  position: fixed;
  bottom: 20px; /* Add padding from the bottom */
  left: 20px; /* Add padding from the right */
  transform: none; /* Remove the transform property */
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-group {
  margin: 1em 0;
}

.form-group label {
  font-family: 'Goudy Bookletter 1911', serif;
  font-size: 4em;
}

.form-group input[type="text"] {
  font-family: 'Indie Flower', cursive;
  background: transparent;
  border: none;
  border-bottom: 1px solid #ffffff20;
  color: #fff;
  font-size: 4em;
  text-align: center;
}

.form-group input[type="text"]:focus {
  outline: none;
  box-shadow: 0 0 0px #719ECE; /* Replace #719ECE with your desired color */
}

.form-group .btn-primary {
  background: url('assets/arrow.png') no-repeat;
  border: 0px;
  width: 150px;
  height: 150px;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  margin-left: 100px;
  margin-top: 50px;
}

.form-group input[type="text"]:valid ~ .btn-primary {
  opacity: 1;
}


.next-button {
  background: url('assets/arrow.png') no-repeat;
  border: 0px;
  width: 150px;
  height: 150px;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  margin-left: 100px;
  margin-top: 50px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  pointer-events: none;
}

.next-button.show {
  opacity: 1;
  pointer-events: auto;
}